import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomePage"),
  },
  {
    path: "/document",
    name: "document",
    component: () => import("../views/Document"),
  },
  {
    path: "/collections/:id",
    name: "datacatalog",
    component: () => import("../components/detail/Detail.vue"),
  },
  {
    path: "/groups/:id",
    name: "group",
    component: () => import("../components/group/Group.vue"),
  },
  {
    path: "/groups/collections/:id",
    redirect: "/collections/:id",
  },
  { path: "*", component: () => import("../views/error/NotFound.vue") },
  { path: "/login", component: () => import("../views/Login.vue") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
