import config from "@/config";
import axios from "axios";

export default class BaseApi {
  submit(method, url, params = {}, configRequest = {}, useStacApi = false) {
    if (!url.includes("https://") && !url.includes("http://")) {
      if (useStacApi) {
        url = config.api_stac + url;
      } else {
        url = config.api_backend + url;
      }
    }
    configRequest = {
      method: method,
      url: url,
      params: method === "get" || method === "GET" ? params : "",
      data: method !== "get" && method !== "GET" ? params : "",
      headers: {
        "Content-Type": "application/json",
      },
      ...configRequest,
    };
    return axios(configRequest);
  }
}
