import BaseApi from "./BaseApi";

class Data extends BaseApi {
  getData() {
    return super.submit("get", "/categories/");
  }
  getGroup(id) {
    return super.submit("get", "/groups/" + id);
  }
  getItemSTAC(url) {
    return super.submit("get", url);
  }
  getCollection(id) {
    return super.submit("get", "/collections/" + id, {}, {}, true);
  }
  getCollections() {
    return super.submit("get", "/collections", {}, {}, true);
  }
  getGroups() {
    return super.submit("get", "/groups/");
  }
}

export default new Data();
